<template>
<breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
<div id="maincontent_container">
    <div class="pt-4">
      <ul class="nav nav-tabs">
        <li class="nav-link"><router-link :to="{ name: 'Login'}">Log in</router-link></li>
        <li class="nav-link"><router-link :to="{ name: 'RegisterAccountLink'}">Web Account registration</router-link></li>
        <li class="nav-link active"><router-link :to="{ name: 'BecomeReseller'}">Become Reseller</router-link></li>
    </ul>
  </div>
    <div class="row pt-4">
        <br>
        <p style="font-weight:bold;font-size:16px">If you are interested in becoming a Ma Labs customer,</p>
        <br>
        <a class="btn btn-primary application_button" target="_blank" href="https://online.malabs.com/application/" style=" height: 53px; width: 390px;">
                <p style="color:white; padding-top: 5px;font-size:18px;">Begin Your Online Application</p>
        </a>

        <hr>
            
        <div style="padding-top:30px;padding-bottom:20px">
                <p><span style="font-weight:bold;font-size:13px">Or, you can download one of the following PDF forms to apply.</span> </p>

            <div style="padding-top:20px;font-size:12px; padding-bottom: 10px;">
                <p><a class="blue_link linkpointer" :href="items[0]">» <u>U.S. Domestic Customer Application Form</u></a>&nbsp; &nbsp;(<a :href="items[0]">Download</a>)
                <a :href="items[0]" target="_blank"><img src="@/assets/images/pdf.png" width="25" height="25"/></a></p>

                <p style="padding-bottom:10px;"><a class="blue_link linkpointer" :href="items[1]">» <u>International Customer Application Form</u></a>&nbsp; &nbsp;(<a :href="items[1]">Download</a>)
                <a :href="items[1]" target="_blank"><img src="@/assets/images/pdf.png" width="25" height="25"/></a></p>
                (Please send back your completed form to Fax:408-941-1088 or Email:sales@malabs.com.)
            </div>
        </div>
        <div class="span12">
            <p class="text">* Some Brands may not be eligible for international purchase and can only be sold to USA Domestic customers only. Please verify with your sales rep for further information.</p>
        </div>
    </div>
</div>
</template>


<script>
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { getAPI } from '../../utils/axios-api'
import { ref } from 'vue'

export default {
  components: { BreadcrumbPage }, 
  setup() {
    const files = ref([])
    const items = ref([])
    const error = ref(null)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Home", link: "Home"})
    breadcrumb_links.value.push({text: "Become Reseller", link: "BecomeReseller"})

    let url = 'content/pages/60'
    getAPI.get(url).then((response) => {  
                files.value = response.data.body
                for(let index in files.value){                  
                    items.value.push('/'+ files.value[index].value)                   
                }
                console.log(items.value) 
        })
        .catch(err => {  
            error.value = "No page Found"        
        })
        return {items, breadcrumb_links}
  }
}
</script>


<style scoped>
hr {
    border-style: inset;
    border-width: 1px;
    border-top: dotted 2px;
    color: #D3D3D3;
}
.application_button {
    background-color: #00509F;
    font-size: 14px;
    color: #ffffff;
    padding-top: 8px;
    margin-bottom: 50px;
}
a.blue_link {
    color: #08c;
}
a, a:active, a:focus, a:visited {
    color: #555;
    text-decoration: none;
}
.text {
    color: red;
}
</style>